import { useSelector } from "react-redux";

import { 
	selectCurrentDeviceId,
    selectFilteredDevices
} from "../../reducers/devices.js"

import DevicesListAsideHeader from "./DevicesListAsideHeader.js";
import DevicesListAsideVolume from "./DevicesListAsideVolume.js";

function DevicesListAside() {
    const currentDeviceId = useSelector(selectCurrentDeviceId); 
    const filteredDevices = useSelector(selectFilteredDevices);

    if (currentDeviceId === 0) return;

    return (
        <div className='calculations-devices-names'>
            {	
                filteredDevices.map(({ ids, name, menuStatus }) => 
                    <div key={name} >
                        <DevicesListAsideHeader name={name} menuStatus={menuStatus} />
                        <DevicesListAsideVolume ids={ids} menuStatus={menuStatus} />
                    </div>
                )
            }
        </div>
    );
}

export default DevicesListAside;
