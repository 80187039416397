import { useState, useRef, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PanelFormInput from "./PanelFormInput.js";
import PanelFormUpload from "./PanelFormUpload.js";
import ModalNewDevice from '../Modal/ModalNewDevice.js';
import Loader from '../Loader/Loader.js';

import { 
    registerManyDevices, 
    selectDevicesStatus,
} from '../../reducers/devices.js';
import { updateMeasurementsByName } from '../../reducers/measurements.js';

import { chooseCategory } from '../../utils/utils.js';
import VolumeTabs from './VolumeTabs.js';

function PanelForm() {
    const [modal, setModal] = useState(false);
    const [ volumeTabs, setVolumeTabs ] = useState([ 
        { 
            id: 0, 
            refProp: createRef(), 
            file: {
                name: '',
                isValid: false,
                measurements: []
            }
        }
    ]);

    const status = useSelector(selectDevicesStatus)

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const inputName = useRef(null);
    const inputCompany = useRef(null);
    const inputSize = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const size = inputSize.current.value.trim().replace(',', '.');
        const company = inputCompany.current.value.trim();
        const name = inputName.current.value.trim();
        const category = chooseCategory(+size);
        const devices = [];

        for (const volumeTab of volumeTabs) {
            const device = {
                name,
                company,
                volume: volumeTab.refProp.current.value.trim(),
                size,
                category,
                measurements: volumeTab.file.measurements
            }

            devices.push(device);
        }
        const copyOfDevices = structuredClone(devices);

        await dispatch(registerManyDevices(devices));
        await dispatch(updateMeasurementsByName(copyOfDevices));

        setModal(true)
    }

    const handleCancel = (e) => {
        e.preventDefault(); 
        
        navigate('/devices');
    }

    const handleModalClose = (e) => {
        e.preventDefault();

        setModal(false);

        navigate('/devices');
    }

    if (status === 'loading')
        return <Loader />

    const isFilesValid = volumeTabs.every(tab => tab.file.isValid);

  	return (
        <div>
            { modal && <ModalNewDevice handleModalClose={handleModalClose} />}
            <div className='panel-form'>
                <h1>Add New Device</h1>
                <form id='add-feedback-form' onSubmit={handleSubmit}>
                    <PanelFormInput ref={inputName} text="Device's name" placeholder="Type device's name" />
                    <PanelFormInput ref={inputCompany} text="Company's name" placeholder="Type company's name" />
                    <PanelFormInput ref={inputSize} text="Device's Size in liters" placeholder="Type devices's size" />
                    <VolumeTabs 
                        volumeTabs={volumeTabs}
                        setVolumeTabs={setVolumeTabs}
                    />
                    <PanelFormUpload 
                        isFilesValid={isFilesValid}
                        handleCancel={handleCancel} />
                </form>
            </div>
        </div>
  	);
}

export default PanelForm;
