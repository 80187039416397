import { useDispatch } from "react-redux";

import { setMenuStatus } from "../../reducers/devices.js"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

function DevicesListAsideHeader({ name, menuStatus }) {
	const dispatch = useDispatch();

    const handleMenuClick = ({ menuStatus, name }) => (e) => {
		if (menuStatus === 'closed') 
			dispatch(setMenuStatus({ menuStatus: 'opened', name })); 
		else if (menuStatus === 'opened') 
			dispatch(setMenuStatus({ menuStatus: 'closed', name })); 
	}

    return (
        <div 
            className={`name-item ${menuStatus}`}
            onClick={handleMenuClick({ menuStatus, name })}
        >
            {name.length > 28 ? `${name.slice(0, 28)}...` : name} 
            {name.length > 28 ? <span className="tooltip">{name}</span> : "" } 

            <span style={{ float: 'right', paddingLeft: '10px'}}>
                <FontAwesomeIcon 
                    icon={menuStatus === 'closed' ? faAngleDown : faAngleUp}  
                    size="xs" 
                />
            </span>
        </div>
    );
}

export default DevicesListAsideHeader;
