import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { 
	setCurrentDeviceId, 
	selectDevices,
	selectCurrentDeviceId,
} from "../../reducers/devices.js"

import { 
	findDeviceById,
} from "../../utils/utils.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeDown, faVolumeUp } from '@fortawesome/free-solid-svg-icons';

function DevicesListAsideVolume({ ids, menuStatus }) {
	const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentDeviceId = useSelector(selectCurrentDeviceId); 

    const allDevices = useSelector(selectDevices);

    const handleActiveDevice = (id) => (e) => {
		const device = findDeviceById(allDevices, id);

		dispatch(setCurrentDeviceId(id));

		navigate(`/calculations/${device.company}/${device.name}/${device.volume}`)
	}
    return (
        <div className="volume-container">
            { menuStatus === 'opened' &&
                ids.map(({ id, volume }) => {
                    return (
                        <div key={id} className="inner-wrapper">
                            <div className="list-line">
                                <FontAwesomeIcon 
                                    icon={+volume < 49 ? faVolumeDown : faVolumeUp}  
                                    size="xs" 
                                />
                            </div>
                            <div 
                                className={`volume-item ${id === currentDeviceId ? 'active': ''}`}
                                onClick={handleActiveDevice(id)}
                            >
                                Volume: {volume}
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default DevicesListAsideVolume;
