import auth from '../utils/auth.js';

const API_ROOT = process.env.REACT_APP_API_ROOT;
const LOGIN_SERVER_BASE_URL = 'https://login.yandex-team.ru';
const LOGIN_SERVER_URL = `${LOGIN_SERVER_BASE_URL}/info?format=jwt`;

export const httpPostDevice = async (device) => {
    try {
        const response = await fetch(`${API_ROOT}/register`, {  
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': auth.getBackendToken()
            },
            body: JSON.stringify(device)
        });
        
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(await response.json());            
        }
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject(e)
        })
    }
}

export const httpGetDevices = async () => {
    try {
        const response = await fetch(`${API_ROOT}/devices`, {  
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': auth.getBackendToken()
            }
        });
        
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(await response.json());            
        }
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject(e)
        })
    }
}

export const httpPutDevice = async (device, endpoint) => {
    try {
        const response = await fetch(`${API_ROOT}/${endpoint}`, {  
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': auth.getBackendToken()
            },
            body: JSON.stringify(device)
        });
        
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(await response.json());            
        }
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject(e)
        })
    }
}

export const httpDeleteDevice = async (id) => {
    try {
        const response = await fetch(`${API_ROOT}/device/${id}`, {  
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': auth.getBackendToken()
            },
        });
        
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(await response.json());            
        }
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject(e)
        })
    }
}

export const httpGetMeasurements = async () => {
    try {
        const response = await fetch(`${API_ROOT}/measurements`, {  
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': auth.getBackendToken()
            }
        });
        
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(await response.json());            
        }
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject(e)
        })
    }
}

export const httpGetMeasurementById = async (id) => {
    try {
        const response = await fetch(`${API_ROOT}/measurement/${id}`, {  
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': auth.getBackendToken()
            }
        });
        
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(await response.json());            
        }
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject(e)
        })
    }
}

export const getUser = async (user) => {
    try {
        const response = await fetch(`${API_ROOT}/signin`, {  
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // 'x-access-token': auth.getBackendToken()
            },
            body: JSON.stringify(user)
        });
        
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(await response.json());            
        }
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject(e)
        })
    }
}

export const createUser = async (user) => {
    try {
        const response = await fetch(`${API_ROOT}/signup`, {  
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // 'x-access-token': auth.getBackendToken()
            },
            body: JSON.stringify(user)
        });
        
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(await response.json());            
        }
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject(e)
        })
    }
}

export const getJwtFromAccessToken = async (accessToken) => {
    try {
        const response = await fetch(LOGIN_SERVER_URL, {  
            method: 'GET',
            headers: {
                'Authorization': 'OAuth ' + accessToken
            }
        });
        
        if (response.ok) {
            return response.text();
        } else {
            throw new Error(await response.text());
        }
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject(e)
        })
    }   
}

export const yandexLogin = async (jwt) => {

    const { login, psuid } = auth.parseJWT(jwt);

    const user = {
        name: login,
        password: psuid
     }

    try {
        const response = await fetch(`${API_ROOT}/auth/yandex`, {  
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user)
        });
        
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(await response.json());            
        }
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject(e)
        })
    }
}
